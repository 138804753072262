import { combineReducers } from "redux"
// import { firebaseReducer } from 'react-redux-firebase'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import commonReducer from './common/duck';
import homeReducer from './home/duck';

export const initialState = {};

 /**
  * Defines mapping of individual view reducers to global state object.
  *
  * Every time a new view is created, entry for that view's reducer is required here.
  */
export const rootReducer = combineReducers({
    home: persistReducer(
      { key: 'homeState', storage: localStorage, stateReconciler: hardSet },
      homeReducer,
    ),
    common: persistReducer(
      { key: 'commonState', storage: localStorage, stateReconciler: hardSet },
      commonReducer,
    ),
});
