import React, { Component } from 'react';

import { LayoutEmpty } from '../common/'

export default class NotFound extends Component {

  render() {
    return (
      <LayoutEmpty className='notfound' heading='Not Found'>
        <p>We don't know anything about this page. If you followed a link to get here, we'd like to know about it so it can be fixed. If you typed this link in manually, double check that it's correct.</p>
      </LayoutEmpty>
    );
  }
}
