import React from 'react';
import styled from 'styled-components';

const EmptyWrapper = styled.div`
    font-family: "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
`

const LayoutEmpty = (props) => {
  return (
    <EmptyWrapper className="main-wrapper layout-empty">

	    <div className="main-content">
	        {props.children}
	    </div>

    </EmptyWrapper>
  );
};

export default LayoutEmpty;
