import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect }  from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'

import store, { persistor } from './store';
import * as routes from '../utils/constants/routes'

import { NotFound, ErrorBoundary } from './common';
import { Home } from './home';

class App extends Component {
	render () {
		return (
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>

					<ErrorBoundary>

						<Router>
							<Switch>

								{/* Unauthenticated views */}
								<Route exact path={routes.HOME} key={routes.HOME} component={Home}/>

								{/* Final catch */}
								<Route path={routes.NOTFOUND} key={routes.NOTFOUND} component={NotFound}/>
								<Redirect to={routes.HOME} />
							</Switch>
						</Router>

					</ErrorBoundary>

				</PersistGate>
			</Provider>
		)
	}
}

export default App;

window.store = store; // NOTE this is exported to give access to system state through console via store.getState();
// TODO Can also export additional actions here if you want to be able to dispatch them in the console
