import React, { Component } from 'react';

import { HOME_HEADING_BLINK_INIT, HOME_HEADING_BLINK_INTERVAL } from '../../utils/constants/delay';

import styled from 'styled-components';

const LandingContainer = styled.div`
	width: 95%;
    height: 100vh;
	margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const LandingHeader = styled.div`
    font-size: 4.2em;
    font-weight: 500;
    color: #666f84;
    line-height: 1.2em;

	& span {
		display: inline-block;
		position: sticky;

		&.blinker::before {
			content: "";
			display: inline-block;
			background-color: ${props => props.visible ? '#666f84' : 'transparent'};
			position: absolute;
			bottom: .1em;
			left: .05em;
			width: .65em;
			height: 5px;
		}
	}
`

const LandingDescrpition = styled.p`
	color: #666f84;
	font-weight: 200;
	max-width: 50em;
	line-height: 1.4;
	margin: 1em 0;
`

const StyledLink = styled.a`
    text-decoration: none;
	color: unset;
	font-weight: 400;
`

const LandingLink = ({href, name}) => {
    return (
        <StyledLink as='a' href={href}>
            {name}
        </StyledLink>
    )
}

class Landing extends Component {
    constructor (props) {
        super(props);

        this.blink = this.blink.bind(this);
    }

    componentWillMount () {
        this.setState({
            underscore_visible: true,
        })

        setTimeout(() => {
            setInterval(() => {
                this.blink()
            }, HOME_HEADING_BLINK_INTERVAL)
        }, HOME_HEADING_BLINK_INIT)
    }

    blink () {
        this.setState({
			underscore_visible: !!!this.state.underscore_visible,
        })
    }

    render () {
        return (
            <LandingContainer>
                <LandingHeader visible={this.state.underscore_visible}>
                    <span className='blinker'>RICHARD</span><span>HUNG</span>
                </LandingHeader>
                <LandingDescrpition>
                    I am a MEng student, studying Computer Science at the University of Warwick. Find me on my <LandingLink href='//www.github.com/Nixivious' name='Github' />, or <LandingLink href='//www.linkedin.com/in/richard-hung/' name='Linkedin' />.
				</LandingDescrpition>
				<LandingDescrpition>
					Résumé available upon request.
				</LandingDescrpition>
				<LandingDescrpition>
					Email me at <LandingLink href='mailto:richardhunghhw@gmail.com' name='richardhunghhw@gmail.com' />.
				</LandingDescrpition>
            </LandingContainer>
        )
    }
}

export default Landing;
