import React, { Component } from 'react';

import { LayoutEmpty } from './';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <LayoutEmpty className='error' heading='Find Your Supervisor'>
          Something went wrong. Please contact support if this happens again.
        </LayoutEmpty>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
