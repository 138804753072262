import types from './types';

const INITIAL_STATE = {
  navsidebar_collasped: true,
  newuser_stage: 0, //TODO 1 = Welcomme
  isNewUser: true,
}

const commonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TOGGLE_NAVSIDEBAR:
      return {...state, navsidebar_collasped: !state.navsidebar_collasped};
    case types.NEWUSER_CHANGESTAGE:
      return {...state, newuser_stage: action.newuser_stage};
    case types.NEWUSER_CHANGESTATE:
      return {...state, newuser_state: action.newuser_state};
    default: return state;
  }
};

export default commonReducer;
