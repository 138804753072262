import { createStore, compose, applyMiddleware } from 'redux';
// import { reactReduxFirebase } from 'react-redux-firebase';
import { persistStore, persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import logger from "redux-logger";
import thunk from 'redux-thunk';

// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database'
// import 'firebase/functions'
// import 'firebase/messaging'
// import firebaseConfig from '../utils/firebaseConfig.js';

import { initialState, rootReducer } from './reducers';

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);

// Define middlewares
let middlewares = [];

middlewares.push(thunk); // for async operations, network calls

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger); // smart console logging of actions
}

let middleware = applyMiddleware(...middlewares); // apply middlewares

// Define enhancers
let enhancers = [];

// enhancers.push(reactReduxFirebase(firebase, {
//   userProfile: 'users',
//   useFirestoreForProfile: false,
//   enableLogging: true,
//   updateProfileOnLogin: false,
// } )); // Firebase

// Compose enhancers
const composedEnhancers = compose(
  middleware,
  ...enhancers,
);

// Initialize persistor
const persistConfig = {
  key: 'root',
  storage: localStorage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create Store
const store = createStore(persistedReducer, initialState, composedEnhancers);

// Persist Store
const persistor = persistStore(store);

export default store;

export { persistor };
