import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Reset } from 'styled-reset'

import App from './app/app';

import * as serviceWorker from './utils/serviceWorkers/serviceWorker';

ReactDOM.render(
	<Fragment>
		<Reset />
		<App />
	</Fragment>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
