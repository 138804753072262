import React from 'react';

import { LayoutEmpty } from '../common/';
import { default as Landing } from './Landing';

const Home = (props) => {
    return (
		<LayoutEmpty>
	        <Landing />
		</LayoutEmpty>
    )
}

export default Home;
